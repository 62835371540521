
import Vue from "vue";
import ProfileData from "@/components/Profile/ProfileData.vue";
import PostsContainer from "@/components/Posts/PostsContainer.vue";
import ActionPanel from "@/components/Profile/ActionPanel.vue";
import { mapGetters } from "vuex";
import ActionPanelLoading from "@/components/Profile/ActionPanelLoading.vue";
import PostsContainerLoading from "@/components/Posts/PostsContainerLoading.vue";
import ProfileDataLoading from "@/components/Profile/ProfileDataLoading.vue";
import ReportUserModal from "@/components/Modals/ReportUserModal.vue";
import RequestMentoringModal from "@/components/Modals/RequestMentoringModal.vue";
import LoadMore from "@/components/Partials/LoadMore.vue";

export default Vue.extend({
  name: "MentorProfilePage",
  components: {
    ProfileData,
    PostsContainer,
    ActionPanel,
    ActionPanelLoading,
    PostsContainerLoading,
    ProfileDataLoading,
    ReportUserModal,
    RequestMentoringModal,
    LoadMore
  },
  data() {
    return {
      loadingProfile: true,
      loading: true,
      tabs: ["Recent", "Post"],
      currentTab: "Recent",
      data: [] as any,
      page: 1,
      load_more: false
    };
  },
  computed: {
    ...mapGetters({
      moduleSettings: "helper/getModuleSettings",
      mentor: "mentor/getMentor",
      mentee: "profile/getProfile"
    }),
    mentoringSessionStatus(): any {
      return this.moduleSettings["mentoring_session"] == 1;
    },
    mentorcastStatus(): any {
      return this.moduleSettings["mentorcast"] == 1;
    },
    mentoringProgramStatus(): any {
      return this.moduleSettings["mentoring_program"] == 1;
    },
    mentorLibraryStatus(): any {
      return this.moduleSettings["mentor_library"] == 1;
    },
    feedbackEngineStatus(): any {
      return this.moduleSettings["feedback_engine"] == 1;
    },
    mentorCommunityStatus(): any {
      return this.moduleSettings["mentor_community"] == 1;
    },
    sessionCertificateStatus(): any {
      return this.moduleSettings["session_certificate"] == 1;
    }
  },
  mounted(): void {
    this.$store.dispatch("helper/GET_MODULE_SETTINGS");
    if (this.mentorcastStatus) {
      this.tabs.push("Mentorcast");
    }
    this.loadingProfile = true;
    this.getMentor().then(() => {
      this.loadingProfile = false;
      this.getActivity();
    });
  },
  methods: {
    getMentor() {
      return this.$store.dispatch("mentor/GET_MENTOR", {
        id: this.$route.params.id
      });
    },
    onTab(tab: any) {
      this.data = [];
      this.currentTab = tab;
      this.getActivity();
    },
    onLoadMore() {
      this.page++;
      this.getActivity(this.page);
    },
    getActivity(page = 1) {
      this.loading = true;
      if (page == 1) {
        this.data = [];
      }
      this.$store
        .dispatch("mentor/GET_ACTIVITY", {
          id: this.$route.params.id,
          tab: this.currentTab,
          page: page
        })
        .then(data => {
          this.loading = false;
          this.page = page;
          this.load_more = page < data.last_page;
          this.data.push(...data.data);
        });
    },
    updateLike(post_id: any, counts: any) {
      const index = this.data.findIndex((i: any) => i.id == post_id);

      if (index > -1) {
        this.data[index].recentable.like_counts = counts;
      }
    }
  }
});
